import React from "react";

const Bottom = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center lg:pt-32 cta-move-top">
    <h1 className="mx-auto font-display tracking-tight lg:text-5xl text-xl md:text-2xl font-bold tracking-tight">
      <span className="inline-block">
        We always
        <span className="relative whitespace-nowrap text-blue-600">
          <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-black">
            {" "}
            welcome talented{" "}
          </span>
        </span>
      </span>
      <span className="inline-block">minds as a part of our Family</span>
    </h1>
  </div>
  
  );
};

export default Bottom;
