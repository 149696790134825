import React from 'react';

const TitleHero = () => {
    return (
        <section className="pt-12 py-20  sm:pt-16">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
  <div className="text-center">
    <p className="max-w-4xl mx-auto mb-4 text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl lg:leading-tight">
      Seamless Chit Fund{" "}
      <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-900 text-4xl md:text-4xl lg:text-5xl font-bold tracking-tight">
        Services
      </span>{" "}
      for You
    </p>
    <h1 className="max-w-2xl mx-auto px-6 text-base lg:text-lg text-gray-600 font-inter">
      Revolutionize your savings with our Seamless Chit Fund Services! Effortless contributions, exciting auctions, and financial empowerment await. Join us for a seamless journey to financial success!
    </h1>
  </div>
</div>

           
        </section>
    );
};

export default TitleHero;
