import React from "react";
import Footer2 from "./footer2";
import Footer1 from "./footer1";
import Footer3 from "./footer3";

const Footermain = () => {
  return (
    <section className="footer">
      <Footer1 />
      <Footer2 />
      {/* <Footer3/> */}
    </section>
  );
};

export default Footermain;
